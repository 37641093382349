const About = () => {
    return (
        <div className="main-wrapper">

        {/* Page Banner Start */}
        <div className="section page-banner-section" style={{backgroundImage: "url(images/about-bg.jpg)"}}>
            <div className="shape-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="944px" height="894px">
                    <defs>
                        <linearGradient id="PSgrad_0" x1="88.295%" x2="0%" y1="0%" y2="46.947%">
                            <stop offset="0%" stop-color="rgb(67,186,255)" stop-opacity="1" />
                            <stop offset="100%" stop-color="rgb(113,65,177)" stop-opacity="1" />
                        </linearGradient>

                    </defs>
                    <path fill-rule="evenodd" fill="rgb(43, 142, 254)" d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z" />
                    <path fill="url(#PSgrad_0)" d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z" />
                </svg>
            </div>
            <div className="shape-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="726.5px" height="726.5px">
                    <path fill-rule="evenodd" stroke="rgb(255, 255, 255)" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" opacity="0.302" fill="none" d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z" />
                </svg>
            </div>
            <div className="shape-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="515px" height="515px">
                    <defs>
                        <linearGradient id="PSgrad_0" x1="0%" x2="89.879%" y1="0%" y2="43.837%">
                            <stop offset="0%" stop-color="rgb(67,186,255)" stop-opacity="1" />
                            <stop offset="100%" stop-color="rgb(113,65,177)" stop-opacity="1" />
                        </linearGradient>

                    </defs>
                    <path fill-rule="evenodd" fill="rgb(43, 142, 254)" d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z" />
                    <path fill="url(#PSgrad_0)" d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z" />
                </svg>
            </div>
            <div className="shape-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="972.5px" height="970.5px">
                    <path fill-rule="evenodd" stroke="rgb(255, 255, 255)" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z" />
                </svg>
            </div>
            <div className="container">
                <div className="page-banner-wrap">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* Page Banner Content Start */}
                            <div className="page-banner text-center">
                                <h2 className="title" style={{background: "linear-gradient(180deg, rgba(10, 16, 28, 0.35), rgba(156, 187, 253, 0.35))", backdropFilter: "blur(3px)", padding: "0.2em", borderRadius: "0.2em"}}>About Us</h2>
                                <ul className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item" style={{background: "linear-gradient(180deg, rgba(10, 16, 28, 0.35), rgba(156, 187, 253, 0.35))", backdropFilter: "blur(3px)", padding: "0.2em", borderRadius: "0.2em"}}><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{background: "linear-gradient(180deg, rgba(10, 16, 28, 0.35), rgba(156, 187, 253, 0.35))", backdropFilter: "blur(3px)", padding: "0.2em", borderRadius: "0.2em"}}>About Us</li>
                                </ul>
                            </div>
                            {/* Page Banner Content End */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Page Banner End */}

        {/* About Start */}
        <div className="section techwix-about-section-07 section-padding">
            <div className="shape-1"></div>
            <div className="container">
                {/* About Wrapper Start */}
                <div className="about-wrap">
                    <div className="row">
                        <div className="col-lg-6">
                            {/* About Image Wrap Start */}
                            <div className="about-img-wrap">
                                <img className="shape-1" src="images/shape/about-shape2.png" alt=""/>
                                {/* <div className="about-img">
                                    <img src="images/about-3.jpg" alt=""/>
                                </div> */}
                                <div className="about-img about-img-2">
                                    <img src="images/keifer2.jpg" alt=""/>
                                </div>
                            </div>
                            {/* About Image Wrap End */}
                        </div>
                        <div className="col-lg-6">
                            {/* About Content Wrap Start */}
                            <div className="about-content-wrap">
                                <div className="section-title">
                                    <h3 className="sub-title">Who we are</h3>
                                    <h2 className="title">Unleashing Potential through Expert Software Development, Management & Support Services.</h2>
                                </div>
                                <p className="text">Our highly tailored IT design, management, and support services are crafted to meet the specific requirements of each client. With a focus on precision and innovation, we provide comprehensive solutions that seamlessly integrate into existing infrastructures, ensuring optimal performance and reliability.</p>
                                {/* About List Start */}
                                <div className="about-list-03">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="about-list-item-03">
                                                <h3 className="title">Our Mission</h3>
                                                <p>We are dedicated to leading technological advancements, creating innovative software applications that empower businesses. Our focus is on delivering user-centric solutions that seamlessly integrate into our clients' operations, driving efficiency and transformative experiences.</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="about-list-item-03">
                                                <h3 className="title">Custom Code</h3>
                                                <p>Every project is uniquely tailored to our clients needs. Making as many changes as required to ensure state of the art products.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* About List End */}
                            </div>
                            {/* About Content Wrap End */}
                        </div>
                    </div>
                </div>
                {/* About Wrapper End */}
            </div>
        </div>
        {/* About End */}

        {/* Counter Start */}
        <div className="section techwix-counter-section-03 techwix-counter-section-04">
            <div className="container">
                <div className="counter-wrap" style={{backgroundImage: "url(images/bg/counter-bg2.jpg)"}}>
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            {/* Single Counter Start */}
                            {/* <div className="single-counter-02 text-center">
                                <span>354+</span>
                                <p>Completed Projects</p>
                            </div> */}
                            {/* Single Counter End */}
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            {/* Single Counter Start */}
                            {/* <div className="single-counter-02 text-center">
                                <span>119+</span>
                                <p>Robotic Automation</p>
                            </div> */}
                            {/* Single Counter End */}
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            {/* Single Counter Start */}
                            {/* <div className="single-counter-02 text-center">
                                <span>99%</span>
                                <p>Web Site Analyse</p>
                            </div> */}
                            {/* Single Counter End */}
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            {/* Single Counter Start */}
                            {/* <div className="single-counter-02 text-center">
                                <span>321+</span>
                                <p>Clients Supoort Done</p>
                            </div> */}
                            {/* Single Counter End */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Counter End */}

        {/* Choose Us Start */}
        <div className="section techwix-choose-us-section section-padding" style={{backgroundImage: "url(images/bg/choose-us-bg3.jpg)"}}>
            <div className="container">
                {/* Choose Us Wrap Start */}
                <div className="choose-us-wrap">
                    <div className="section-title text-center">
                        <h3 className="sub-title">REASON TO CHOOSE US</h3>
                        <h2 className="title">Software solutions tailored to your company.</h2>
                    </div>
                    <div className="choose-us-content-wrap">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/ser-icon22.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Custom Web App Development</a></h3>
                                        <p>Tailored development of web applications based on client requirements.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/counter-1.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">E-commerce Development</a></h3>
                                        <p>Building online stores and integrating payment gateways.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/ser-icon21.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">API Development and Integration</a></h3>
                                        <p>Building and integrating APIs to connect different software components.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/ser-icon12.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Blockchain Development</a></h3>
                                        <p>Building decentralized applications (dApps) using blockchain technology.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/ser-icon9.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Maintenance and Support</a></h3>
                                        <p>Providing ongoing support, bug fixes, and updates.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/counter-3.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Robotic Process Automation</a></h3>
                                        <p>Automate repetitive tasks within business processes.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/ser-icon10.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Content Management Systems</a></h3>
                                        <p>Creating custom CMS solutions.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/counter-2.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Cloud Integration</a></h3>
                                        <p>Deploying and managing applications on cloud platforms.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Choose Us Wrap End */}
            </div>
        </div>
        {/* Choose Us End */}

        {/* Skill Start */}
        <div className="section techwix-skill-section-02 techwix-skill-section-03 section-padding">
            <div className="container">
                <div className="skill-wrap">
                    <div className="row">
                        <div className="col-lg-6">
                            {/* Skill Left Start */}
                            <div className="skill-left">
                                <div className="section-title">
                                    <h2 className="title">Excel your company in a digital world</h2>
                                </div>
                                <div className="about-list">
                                    <ul>
                                        <li>
                                            <span className="about-icon"><i className="fas fa-check"></i></span>
                                            <span className="about-text">We always focus on technical excellence </span>
                                        </li>
                                        <li>
                                            <span className="about-icon"><i className="fas fa-check"></i></span>
                                            <span className="about-text"> Wherever you’re going, we bring ideas and excitement </span>
                                        </li>
                                        <li>
                                            <span className="about-icon"><i className="fas fa-check"></i></span>
                                            <span className="about-text">We handle every project uniquely based on your needs </span>
                                        </li>
                                    </ul>
                                </div>
                                {/* About Author Info Wrap Start */}
                                <div className="about-author-info-wrap">
                                    <div className="about-author">
                                        <img src="images/sign.png" alt="" style={{width: "15em"}} />
                                        <h3 className="name">Keifer Rasner</h3>
                                        <span className="designation">Lead Developer, G2 Technologies</span>
                                    </div>
                                    <div className="about-info">
                                        <p>Call to ask any question </p>
                                        <h3 className="number">906-290-3767</h3>
                                    </div>
                                </div>
                                {/* About Author Info Wrap End */}
                            </div>
                            {/* Skill Left End */}
                        </div>
                        <div className="col-lg-6">
                            {/* Skill Right Start */}
                            <div className="skill-right">
                                <p className="text">In the dynamic landscape of today's digital world, our software company is dedicated to helping your business thrive. We specialize in providing innovative solutions that empower your company to excel.</p>
                                <div className="counter-bar">
                                    {/* Skill Item Start */}
                                    <div className="skill-item">
                                        <span className="title">Customized Applications</span>
                                        {/* <div className="skill-bar">
                                            <div className="bar-inner">
                                                <div className="bar progress-line color-1" data-width="80">
                                                    <span className="skill-percent"><span className="counter">80</span>%</span>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* Skill Item End */}
                                    {/* Skill Item Start */}
                                    <div className="skill-item">
                                        <span className="title">Cloud Integrations</span>
                                        {/* <div className="skill-bar">
                                            <div className="bar-inner">
                                                <div className="bar progress-line color-1" data-width="95">
                                                    <span className="skill-percent"><span className="counter">95</span>%</span>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* Skill Item End */}
                                    {/* Skill Item Start */}
                                    <div className="skill-item">
                                        <span className="title">AI Solutions</span>
                                        {/* <div className="skill-bar">
                                            <div className="bar-inner">
                                                <div className="bar progress-line color-1" data-width="80">
                                                    <span className="skill-percent"><span className="counter">80</span>%</span>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* Skill Item End */}
                                </div>
                            </div>
                            {/* Skill Right End */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Skill End */}

    </div>
    )
}

export default About