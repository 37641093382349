import React from 'react';

const Alert = ({ type, text }) => {
    return (
        <div className={`alert position-fixed alert-main alert-dismissible fade show ${type}`} style={{ right: 0, top: "5%", zIndex: 999 }} role="alert">
            <strong>{type === 'alert-danger' ? 'Oops...' : 'Success!'}</strong> {text}
        </div>
    )
};

export default Alert