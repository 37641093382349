import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Login from './components/Login/Login';
import { useEffect } from 'react';
import Footer from './components/Home/Footer';
import Cta from './components/Home/Cta';
import OffCanvas from './components/Home/OffCanvas';
import Pricing from './components/Pricing/Pricing';
import Service from './components/Service/Service';
import WebAppSolutions from './components/WebAppSolutions/WebAppSolutions';
import AiSolution from './components/AiSolution/AiSolution';
import ApiSolution from './components/ApiSolution/ApiSolution';
import Blog from './components/Blog/Blog';
import BlogSingle from './components/Blog/BlogSingle';
import Terms from './components/Terms/Terms';
import Payments from './components/Payments/Payments';
// import Dashboard from './components/Dashboard/Dashboard';
// import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

const App = () => {
    useEffect(() => {
        setTimeout(() => {
            document.querySelector("#preloader").style.display = "none";
        }, 750);
    }, []);

    return (
        <Router>
            <LocationListener />
        </Router>
    );
};

// LocationListener.js
const LocationListener = () => {
    const location = useLocation();
    const isDashboardRoute = location.pathname.startsWith('/dashboard');

    return (
        <>
            {!isDashboardRoute && (
                <>
                    <Header />
                    <OffCanvas />
                </>
            )}
            <div id="preloader">
                <div className="preloader">
                    <span></span>
                    <span></span>
                </div>
            </div>
            <Routes>
                <Route path='/' element={<Home/>} />
                <Route path='/about' element={<About/>} />
                <Route path='/contact' element={<Contact/>} />
                <Route path='/login' element={<Login/>} />
                <Route path='/pricing' element={<Pricing/>} />
                <Route path='/service' element={<Service/>} />
                <Route path='/websolutions' element={<WebAppSolutions/>} />
                <Route path='/aisolutions' element={<AiSolution/>} />
                <Route path='/apisolutions' element={<ApiSolution/>} />
                <Route path='/blog' element={<Blog/>} />
                <Route path="/trends-and-strategies-in-modern-web-development" element={<BlogSingle />} />
                <Route path="/redefining-the-online-presence" element={<BlogSingle />} />
                <Route path="/from-concept-to-clicks" element={<BlogSingle />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/payment-portal" element={<Payments />} />
                {/* <Route
                    path="/dashboard"
                    element={<ProtectedRoute child={<Dashboard />} />}
                /> */}
                {/* <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute redirectTo="/login" child={<Dashboard />} />
                    }
                /> */}
            </Routes>
            {!isDashboardRoute && (
                <>
                    <Cta />
                </>
            )}
            <Footer />
        </>
    );
};

export default App;