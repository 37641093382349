import React from 'react';
import { registrationFormInputs } from '../../../lib/tmpData';

const Form = ({ formData, setFormData, handleSubmit }) => {

    return (
        <div className="login-register-form">
            <form onSubmit={handleSubmit}>
                {registrationFormInputs.map((input) => (
                    <div className="single-form" key={input.key}>
                        <input
                            type={input.type}
                            className="form-control"
                            placeholder={input.placeholder}
                            onChange={(e) => setFormData({ ...formData, [input.key]: e.target.value })}
                        />
                    </div>
                ))}
                <div className="form-btn">
                    <button className="btn" type="submit">
                        Register
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Form;