import React from 'react';

const AuthSection = ({ children }) => (
    <div className="section login-register-section section-padding-02">
        <div className="container">
            <div className="login-register-wrap">
                <div className="row gx-5">{children}</div>
            </div>
        </div>
    </div>
);

export default AuthSection;