const Footer = () => {
    return (
        <div className="section footer-section footer-section-03" style={{backgroundImage: "url(images/bg/footer-bg.jpg)"}}>

            <div className="container">
                {/* Footer Widget Wrap Start */}
                <div className="footer-widget-wrap">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            {/* Footer Widget Start */}
                            <div className="footer-widget-about">
                                <a className="footer-logo" href="/"><img src="images/LOGO.png" alt="Logo" style={{width: "60px"}}/></a>
                                <p>We are dedicated to leading technological advancements, creating innovative software applications that empower businesses.</p>
                                <div className="footer-social">
                                    <ul className="social">
                                        <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="/"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* Footer Widget End */}
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            {/* Footer Widget Start */}
                            <div className="footer-widget">
                                <h4 className="footer-widget-title">Useful Links</h4>

                                <div className="widget-link">
                                    <ul className="link">
                                        <li><a href="/terms">Terms & Conditions</a></li>
                                        <li><a href="/about">About Company</a></li>
                                        <li><a href="/">Payment Gatway</a></li>
                                        <li><a href="/">Policy</a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* Footer Widget End */}
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            {/* Footer Widget Start */}
                            <div className="footer-widget">
                                <h4 className="footer-widget-title">Our Services</h4>

                                <div className="widget-link">
                                    <ul className="link">
                                        <li><a href="/websolutions">Web & Application Development</a></li>
                                        <li><a href="/apisolutions">API Solutions</a></li>
                                        <li><a href="/aisolutions">AI Solutions</a></li>
                                        <li><a href="/service">Content Management Systems</a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* Footer Widget End */}
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            {/* Footer Widget Start */}
                            <div className="footer-widget">
                                <h4 className="footer-widget-title">Contact Information</h4>

                                <div className="widget-info">
                                    <ul>
                                        <li>
                                            <div className="info-icon">
                                                <i className="flaticon-phone-call"></i>
                                            </div>
                                            <div className="info-text">
                                                <span><a href="/">906 290 3767</a></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="info-icon">
                                                <i className="far fa-envelope-open"></i>
                                            </div>
                                            <div className="info-text">
                                                <span><a href="mailto:krasner@g-2technologies.com">krasner@g-2technologies.com</a></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Footer Widget End */}
                        </div>
                    </div>
                </div>
                {/* Footer Widget Wrap End */}
            </div>

            {/* Footer Copyright Start */}
            <div className="footer-copyright-area">
                <div className="container">
                    <div className="footer-copyright-wrap">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                {/* Footer Copyright Text Start */}
                                <div className="copyright-text text-center">
                                    <p>© Copyrights 2024 G2 Technologies All rights reserved. </p>
                                </div>
                                {/* Footer Copyright Text End */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer Copyright End */}
        </div>
    )
}

export default Footer