const Header = () => {
    return (
        <div id="header" className="section header-section">

        <div className="container">

            {/* Header Wrap Start  */}
            <div className="header-wrap">

                <div className="header-logo">
                    <a href="/"><img src="images/LOGO.png" alt="" style={{width: "60px", height: "60px"}}/></a>
                </div>

                <div className="header-menu d-none d-lg-block">
                    <ul className="main-menu">
                        <li className="active-menu">
                            <a href="/">Home</a>
                            <span className="menu-icon"></span>
                            <ul className="sub-menu">
                                <li className="active"><a href="/">Home Main</a></li>
                                <li><a href="/websolutions">Web & App Solutions</a></li>
                                <li><a href="/aisolutions">AI Solutions</a></li>
                                {/* <li><a href="index-3.html">Cyber Security</a></li> */}
                                <li><a href="/apisolutions">API Solutions</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="/about">About Us</a>
                        </li>
                        <li>
                            <a href="#">Pages</a>
                            <span className="menu-icon"></span>
                            <ul className="sub-menu">
                                {/* <li><a href="team.html">Our Team</a></li> */}
                                <li><a href="/service">Service</a></li>
                                {/* <li><a href="#">Why Choose Us</a></li> */}
                                {/* <li><a href="testimonial.html">Testimonial</a></li> */}
                                <li><a href="/pricing">Pricing</a></li>
                                <li><a href="https://g2dash.com">Login & Register</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="/blog">Blog</a>
                        </li>
                        <li><a href="/contact">Contact</a></li>
                    </ul>
                </div>

                {/* Header Meta Start */}
                <div className="header-meta">
                    <div className="header-btn d-none d-xl-block">
                        <a className="btn" href="https://g2dash.com">Get Started</a>
                    </div>
                    {/* Header Toggle Start */}
                    <div className="header-toggle d-lg-none">
                        <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample">
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                    {/* Header Toggle End */}
                </div>
                {/* Header Meta End  */}

            </div>
            {/* Header Wrap End  */}

        </div>
    </div>
    )
}

export default Header