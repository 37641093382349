import About from "./About"
import ChooseUs from "./ChooseUs"
import Hero from "./Hero"
import Service from "./Service"
import Skill from "./Skill"

const Home = () => {
    return (
        <div className="main-wrapper">
            <Hero />
            <Service />
            <About />
            <ChooseUs />
            <Skill />
            <div className="progress-wrap">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>
        </div>
    )
}

export default Home