const Service = () => {

    return (
        <div className="main-wrapper">

        {/* Page Banner Start */}
        <div className="section page-banner-section" style={{backgroundImage: "url(images/service-bg.jpg)"}}>
            <div className="shape-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="944px" height="894px">
                    <defs>
                        <linearGradient id="PSgrad_0" x1="88.295%" x2="0%" y1="0%" y2="46.947%">
                            <stop offset="0%" stop-color="rgb(67,186,255)" stop-opacity="1" />
                            <stop offset="100%" stop-color="rgb(113,65,177)" stop-opacity="1" />
                        </linearGradient>

                    </defs>
                    <path fill-rule="evenodd" fill="rgb(43, 142, 254)" d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z" />
                    <path fill="url(#PSgrad_0)" d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z" />
                </svg>
            </div>
            <div className="shape-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="726.5px" height="726.5px">
                    <path fill-rule="evenodd" stroke="rgb(255, 255, 255)" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" opacity="0.302" fill="none" d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z" />
                </svg>
            </div>
            <div className="shape-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="515px" height="515px">
                    <defs>
                        <linearGradient id="PSgrad_0" x1="0%" x2="89.879%" y1="0%" y2="43.837%">
                            <stop offset="0%" stop-color="rgb(67,186,255)" stop-opacity="1" />
                            <stop offset="100%" stop-color="rgb(113,65,177)" stop-opacity="1" />
                        </linearGradient>

                    </defs>
                    <path fill-rule="evenodd" fill="rgb(43, 142, 254)" d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z" />
                    <path fill="url(#PSgrad_0)" d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z" />
                </svg>
            </div>
            <div className="shape-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="972.5px" height="970.5px">
                    <path fill-rule="evenodd" stroke="rgb(255, 255, 255)" stroke-width="1px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z" />
                </svg>
            </div>
            <div className="container">
                <div className="page-banner-wrap">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* Page Banner Content Start */}
                            <div className="page-banner text-center">
                                <h2 className="title" style={{background: "linear-gradient(180deg, rgba(10, 16, 28, 0.35), rgba(156, 187, 253, 0.35))", backdropFilter: "blur(3px)", padding: "0.2em", borderRadius: "0.2em"}}>Our Services</h2>
                                <ul className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item" style={{background: "linear-gradient(180deg, rgba(10, 16, 28, 0.35), rgba(156, 187, 253, 0.35))", backdropFilter: "blur(3px)", padding: "0.2em", borderRadius: "0.2em"}}><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{background: "linear-gradient(180deg, rgba(10, 16, 28, 0.35), rgba(156, 187, 253, 0.35))", backdropFilter: "blur(3px)", padding: "0.2em", borderRadius: "0.2em"}}>Services</li>
                                </ul>
                            </div>
                            {/* Page Banner Content End */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Page Banner End */}

        {/* Choose Us Start */}
        <div className="section techwix-choose-us-section techwix-choose-us-section-04 section-padding">
            <div className="container">
                {/* Choose Us Wrap Start */}
                <div className="choose-us-wrap">
                    <div className="choose-us-content-wrap">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/ser-icon22.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Custom Web App Development</a></h3>
                                        <p>Tailored development of web applications based on client requirements.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/counter-1.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">E-commerce Development</a></h3>
                                        <p>Building online stores and integrating payment gateways.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/ser-icon21.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">API Development and Integration</a></h3>
                                        <p>Building and integrating APIs to connect different software components.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/ser-icon12.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Blockchain Development</a></h3>
                                        <p>Building decentralized applications (dApps) using blockchain technology.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/ser-icon9.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Maintenance and Support</a></h3>
                                        <p>Providing ongoing support, bug fixes, and updates.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/counter-3.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Robotic Process Automation</a></h3>
                                        <p>Automate repetitive tasks within business processes.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/ser-icon10.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Content Management Systems</a></h3>
                                        <p>Creating custom CMS solutions.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item-02">
                                    <div className="choose-us-img">
                                        <img src="images/counter-2.png" alt=""/>
                                    </div>
                                    <div className="choose-us-content">
                                        <h3 className="title"><a href="#">Cloud Integration</a></h3>
                                        <p>Deploying and managing applications on cloud platforms.</p>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Choose Us Wrap End */}
            </div>
        </div>
        {/* Choose Us End */}

        {/* Pricing Start */}
        <div className="section techwix-pricing-section section-padding-02" style={{marginBottom: "14em"}}>
            <div className="container">
                {/* Pricing Wrap Start */}
                <div className="pricing-wrap">
                    <div className="section-title text-center">
                        <h3 className="sub-title">Pricing Plans</h3>
                        <h2 className="title">Affordable pricing for all</h2>
                    </div>
                    <div className="pricing-content-wrap">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                {/* Single Pricing Start */}
                                <div className="single-pricing">
                                    <div className="pricing-badge">
                                        <span className="title">Gold</span>
                                    </div>
                                    <div className="pricing-price">
                                        <span className="currency">$</span>
                                        <h3 className="price">100<span>/Month</span></h3>
                                    </div>
                                    <div className="pricing-content">
                                        <ul className="pricing-list">
                                            <li>3 Changes/month</li>
                                            <li>Standard SLA's & Support</li>
                                            <li>Limited Storage & API Calls</li>
                                            <li>Continuous Security Updates</li>
                                        </ul>
                                        <div className="pricing-btn">
                                            <a className="btn" href="https://g2dash.com">Try It Now</a>
                                        </div>
                                    </div>
                                </div>
                                {/* Single Pricing End */}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                {/* Single Pricing Start */}
                                <div className="single-pricing active">
                                    <div className="pricing-badge">
                                        <span className="title">Platinum</span>
                                    </div>
                                    <div className="pricing-price">
                                        <span className="currency">$</span>
                                        <h3 className="price">200<span>/Month</span></h3>
                                    </div>
                                    <div className="pricing-content">
                                        <ul className="pricing-list">
                                            <li>10 Changes/month</li>
                                            <li>Enhanced SLA's & Support</li>
                                            <li>Enhanced Storage & API Calls</li>
                                            <li>Continuous Security Updates</li>
                                        </ul>
                                        <div className="pricing-btn">
                                            <a className="btn" href="https://g2dash.com">Try It Now</a>
                                        </div>
                                    </div>
                                </div>
                                {/* Single Pricing End */}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                {/* Single Pricing Start */}
                                <div className="single-pricing">
                                    <div className="pricing-badge">
                                        <span className="title">Diamond</span>
                                    </div>
                                    <div className="pricing-price">
                                        <span className="currency">$</span>
                                        <h3 className="price">400<span>/Month</span></h3>
                                    </div>
                                    <div className="pricing-content">
                                        <ul className="pricing-list">
                                        <li>Unlimited Changes + CMS System</li>
                                            <li>24/7 Support</li>
                                            <li>Unlimited Storage & API Calls</li>
                                            <li>Continuous Security Updates</li>
                                            <li>Advanced Analytics</li>
                                            <li>Reputation Management</li>
                                            <li>Centralized Communication System</li>
                                            <li>AI-based Followups</li>
                                        </ul>
                                        <div className="pricing-btn">
                                            <a className="btn" href="https://g2dash.com">Try It Now</a>
                                        </div>
                                    </div>
                                </div>
                                {/* Single Pricing End */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Pricing Wrap End */}
                <div className="col-lg-6">
                    {/* Features Item Start */}
                    <div className="features-item">
                        <div className="features-icon">
                            <img src="assets/images/feat-4.png" alt=""/>
                        </div>
                        <div className="features-content">
                            <h3 className="title"><a href="#">Custom Application & Solutions</a></h3>
                            <p>Pricing for Custom applications, API integrations, & AI solutions determined upon project requirements.</p>
                            <a className="lern-more" href="/service">Learn More <i className="fas fa-long-arrow-alt-right"></i></a>
                        </div>
                    </div>
                    {/* Features Item End */}
                </div>
            </div>
        </div>
        {/* Pricing End */}

    </div>
    )
}

export default Service