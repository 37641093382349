const Skill = () => {
    return (
        <div className="section techwix-skill-section-02 section-padding">
            <div className="container">
                <div className="skill-wrap">
                    <div className="row">
                        <div className="col-lg-6">
                            {/* Skill Left Start */}
                            <div className="skill-left">
                                <div className="section-title">
                                    <h2 className="title">Expert Skills Tailored for Your Unique Software Needs</h2>
                                </div>
                                <div className="experience-wrap">
                                    {/* <div className="experience">
                                        <h2 className="number">25</h2>
                                        <span>Years of <br></br> experience</span>
                                    </div> */}
                                    <div className="experience-text">
                                        <p>Our expert team is dedicated to tailoring cutting-edge solutions that not only meet but exceed your unique software requirements, ensuring a seamless and satisfying experience for your users.</p>
                                        <a className="learn-more" href="/about">learn More About Us <i className="fas fa-long-arrow-alt-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/* Skill Left End */}
                        </div>
                        <div className="col-lg-6">
                            {/* Skill Right Start */}
                            <div className="skill-right">
                                <div className="counter-bar">
                                    {/* Skill Item Start */}
                                    <div className="skill-item">
                                        <span className="title">Customized Applications</span>
                                    </div>
                                    {/* Skill Item End */}
                                    {/* Skill Item Start */}
                                    <div className="skill-item">
                                        <span className="title">Cloud Integrations</span>
                                    </div>
                                    {/* Skill Item End */}
                                    {/* Skill Item Start */}
                                    <div className="skill-item">
                                        <span className="title">AI Solutions</span>
                                    </div>
                                    {/* Skill Item End */}
                                    {/* Skill Item Start */}
                                    <div className="skill-item">
                                        <span className="title">API Solutions</span>
                                    </div>
                                    {/* Skill Item End */}
                                </div>
                            </div>
                            {/* Skill Right End */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skill