import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { blogData } from "../../lib/tmpData"
const BlogSingle = () => {
    const [currBlog, setCurrBlog] = useState({})
    const [otherBlogs, setOtherBlogs] = useState([])
    const currLoc = useLocation()
    useEffect(() => {
        let temp = blogData.filter(a => a.link === currLoc.pathname.split("/")[1])
        let otherBlogsTemp = blogData.filter(a => a.link !== currLoc.pathname.split("/")[1])
        setCurrBlog({...temp[0]})
        setOtherBlogs([...otherBlogsTemp])
    },[])
    return (
        <div className="main-wrapper" style={{marginBottom: "8em"}}>

        {/* Page Banner Start */}
        <div className="section page-banner-section" style={{backgroundImage: "url(images/bg/blog-bg.jpg)"}}>
            <div className="shape-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="944px" height="894px">
                    <defs>
                        <linearGradient id="PSgrad_0" x1="88.295%" x2="0%" y1="0%" y2="46.947%">
                            <stop offset="0%" stopColor="rgb(67,186,255)" stopOpacity="1" />
                            <stop offset="100%" stopColor="rgb(113,65,177)" stopOpacity="1" />
                        </linearGradient>

                    </defs>
                    <path fillRule="evenodd" fill="rgb(43, 142, 254)" d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z" />
                    <path fill="url(#PSgrad_0)" d="M39.612,410.76 L467.344,29.824 C516.51,-13.476 590.638,-9.93 633.938,39.613 L914.192,317.344 C957.492,366.50 953.109,440.637 904.402,483.938 L476.671,864.191 C427.964,907.492 353.376,903.109 310.76,854.402 L29.823,576.670 C-13.477,527.963 -9.94,453.376 39.612,410.76 Z" />
                </svg>
            </div>
            <div className="shape-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="726.5px" height="726.5px">
                    <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt" strokeLinejoin="miter" opacity="0.302" fill="none" d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z" />
                </svg>
            </div>
            <div className="shape-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="515px" height="515px">
                    <defs>
                        <linearGradient id="PSgrad_0" x1="0%" x2="89.879%" y1="0%" y2="43.837%">
                            <stop offset="0%" stopColor="rgb(67,186,255)" stopOpacity="1" />
                            <stop offset="100%" stopColor="rgb(113,65,177)" stopOpacity="1" />
                        </linearGradient>

                    </defs>
                    <path fillRule="evenodd" fill="rgb(43, 142, 254)" d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z" />
                    <path fill="url(#PSgrad_0)" d="M19.529,202.280 L230.531,14.698 C254.559,-6.661 291.353,-4.498 312.714,19.528 L500.295,230.531 C521.656,254.558 519.493,291.353 495.466,312.713 L284.463,500.295 C260.436,521.655 223.641,519.492 202.281,495.465 L14.699,284.462 C-6.660,260.435 -4.498,223.640 19.529,202.280 Z" />
                </svg>
            </div>
            <div className="shape-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="972.5px" height="970.5px">
                    <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt" strokeLinejoin="miter" fill="none" d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z" />
                </svg>
            </div>
            <div className="container">
                <div className="page-banner-wrap">
                    <div className="row">
                        <div className="col-lg-12">
                            {/* Page Banner Content Start */}
                            <div className="page-banner text-center">
                                <h2 className="title" style={{background: "linear-gradient(180deg, rgba(10, 16, 28, 0.35), rgba(156, 187, 253, 0.35))", backdropFilter: "blur(3px)", padding: "0.2em", borderRadius: "0.2em"}}>Blog Details</h2>
                                <ul className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item" style={{background: "linear-gradient(180deg, rgba(10, 16, 28, 0.35), rgba(156, 187, 253, 0.35))", backdropFilter: "blur(3px)", padding: "0.2em", borderRadius: "0.2em"}}><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{background: "linear-gradient(180deg, rgba(10, 16, 28, 0.35), rgba(156, 187, 253, 0.35))", backdropFilter: "blur(3px)", padding: "0.1em", borderRadius: "0.2em"}}>Blog Details</li>
                                </ul>
                            </div>
                            {/* Page Banner Content End */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Page Banner End */}

        {/* Blog Details Start */}
        <div className="section blog-details-section section-padding-02">
            <div className="container">
                {/* Blog Details Wrap Start */}
                <div className="blog-details-wrap">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8">
                            {/* Blog Details Post Start */}
                            <div className="blog-details-post">
                                {/* Single Blog Start */}
                                <div className="single-blog-post single-blog">
                                    <div className="blog-image">
                                        <a href="#"><img src={currBlog.imgBig} alt=""/></a>
                                        <div className="top-meta">
                                            <span className="date"><span>{currBlog.day}</span>{currBlog.month}</span>
                                        </div>
                                    </div>
                                    <div className="blog-content">
                                        <div className="blog-meta">
                                            <span className="tag"><i className="far fa-bookmark"></i> Technology / Business</span>
                                            <span><i className="fas fa-user"></i> <a href="#">{currBlog.author}</a></span>
                                            <span><i className="far fa-comments"></i> 0 Comments</span>
                                        </div>
                                        <h3 className="title">{currBlog.title}</h3>
                                        <p className="text">{currBlog.para}</p>
                                    </div>
                                </div>
                                {/* Single Blog End */}
                                <div className="blog-details-content">
                                    {/* Blog Blockquote Start */}
                                    <div className="blog-quote" style={{marginBottom: "40px"}}>
                                        <blockquote className="blockquote">
                                            <p>{currBlog?.blockQuote}</p>
                                        </blockquote>
                                    </div>
                                    {/* Blog Blockquote End */}
                                    {/* Blog Details Text Start */}
                                    {currBlog.details && currBlog.details.map((detail,i) => (
                                        <div className="blog-details-text" key={i}>
                                            {detail.header && <h3>{detail.headerText}</h3>}
                                            <p>{detail.text}</p>
                                        </div>
                                    ))}
                                    {/* Blog Details Text End */}
                                    {/* Blog Details Tag Share Start */}
                                    <div className="blog-details-tag-share">
                                        <div className="blog-details-share">
                                            <ul className="social-share">
                                                <li><a className="share-facebook" href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a className="share-twitter" href="#"><i className="fab fa-twitter"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* Blog Details Tag Share End */}
                                    {/* Blog Details Post Navigation Start */}
                                    <div className="techwix-post-pagination">
                                        {otherBlogs && otherBlogs.map(blog => (
                                            <div className="previous-post" key={blog.id}>
                                                <div className="blog-pagination-post">
                                                    <div className="post-thumb">
                                                        <a href={blog.link}>
                                                            <i className="flaticon-back"></i>
                                                            <img src={blog.imgXS} alt=""/>
                                                        </a>
                                                    </div>
                                                    <div className="post-content">
                                                        <h4 className="title"><a href={blog.link}>{blog.title}</a></h4>
                                                        <span className="date"><i className="far fa-calendar-alt"></i>{blog.fullDate}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* Blog Details Post Navigation End */}
                                    {/* Commtent Wrap Start */}
                                    <div className="comment-wrap">
                                        {/* Commtent Form Start */}
                                        <div className="comment-form">
                                            <h3 className="comment-title">Comments (3)</h3>
                                            <p>Your email address will not be published. Required fields are marked *</p>
                                            {/* Commtent Form Wrap Start */}
                                            <div className="comment-form-wrap">
                                                <form action="#">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            {/*  Single Form Start  */}
                                                            <div className="single-form">
                                                                <input className="form-control" type="text" placeholder="Your Name"/>
                                                            </div>
                                                            {/*  Single Form End  */}
                                                        </div>
                                                        <div className="col-md-6">
                                                            {/*  Single Form Start  */}
                                                            <div className="single-form">
                                                                <input className="form-control" type="email" placeholder="Your Email"/>
                                                            </div>
                                                            {/*  Single Form End  */}
                                                        </div>
                                                        <div className="col-md-12">
                                                            {/*  Single Form Start  */}
                                                            <div className="single-form">
                                                                <textarea className="form-control" placeholder="Your Message"></textarea>
                                                            </div>
                                                            {/*  Single Form End  */}
                                                        </div>
                                                        <div className="col-md-12">
                                                            {/*  Single Form Start  */}
                                                            <div className="form-btn">
                                                                <button className="btn" type="submit">Submit</button>
                                                            </div>
                                                            {/*  Single Form End  */}
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            {/* Commtent Form Wrap End */}
                                        </div>
                                        {/* Commtent Form End */}
                                    </div>
                                    {/* Commtent Wrap End */}
                                </div>
                            </div>
                            {/* Blog Details Post End */}
                        </div>
                        <div className="col-xl-3 col-lg-4">
                            {/* Blog Sidebar Start */}
                            <div className="blog-sidebar">

                                {/* Sidebar Widget Start */}
                                <div className="sidebar-widget">
                                    {/* Widget Title Start */}
                                    <div className="widget-title">
                                        <h3 className="title">Popular Posts</h3>
                                    </div>
                                    {/* Widget Title End */}
                                    {/* Widget Recent Post Start */}
                                    <div className="recent-posts">
                                        <ul>
                                            {otherBlogs && otherBlogs.map(blog => (
                                                <li key={blog.id}>
                                                    <a className="post-link" href={blog.link}>
                                                        <div className="post-thumb">
                                                            <img src={blog.imgXXS} alt=""/>
                                                        </div>
                                                        <div className="post-text">
                                                            <h4 className="title">{blog.title}</h4>
                                                            <span className="post-meta"><i className="far fa-calendar-alt"></i> {blog.fullDate}</span>
                                                        </div>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    {/* Widget Recent Post End */}
                                </div>
                                {/* Sidebar Widget End */}

                                <div className="sidebar-widget">
                                    <div className="widget-banner" style={{backgroundImage: "url(images/leading-platform.jpg)"}} >
                                        <div className="banner-content">
                                            <h4 className="title" style={{background: "linear-gradient(180deg, rgba(10, 16, 28, 0.35), rgba(156, 187, 253, 0.35))", backdropFilter: "blur(3px)", padding: "0.1em", borderRadius: "0.1em"}}>The leading platform</h4>
                                            <a className="btn" href="/contact">Get Started</a>
                                        </div>
                                    </div>
                                </div>

                                {/* Sidebar Widget Start */}
                                <div className="sidebar-widget">
                                    {/* Widget Title Start */}
                                    <div className="widget-title">
                                        <h3 className="title">Categories</h3>
                                    </div>
                                    {/* Widget Title End */}
                                    {/* Widget Category Start */}
                                    <ul className="category">
                                        <li className="cate-item"><a href="/blog"><i className="flaticon-next"></i> Technology <span className="post-count">3</span></a></li>
                                        <li className="cate-item"><a href="/blog"><i className="flaticon-next"></i> Innovation <span className="post-count">5</span></a></li>
                                        <li className="cate-item"><a href="/blog"><i className="flaticon-next"></i> Learning <span className="post-count">3</span></a></li>
                                        <li className="cate-item"><a href="/blog"><i className="flaticon-next"></i> Information <span className="post-count">3</span></a></li>
                                    </ul>
                                    {/* Widget Category End */}
                                </div>
                                {/* Sidebar Widget End */}
                            </div>
                            {/* Blog Sidebar End */}
                        </div>
                    </div>
                </div>
                {/* Blog Details Wrap End */}
            </div>
        </div>
        {/* Blog Details End */}

    </div>
    )
}

export default BlogSingle