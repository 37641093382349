const Service = () => {
    return (
        <div className="section techwix-service-section-03" style={{backgroundImage: "url(images/bg/service-bg3.jpg)"}} >
            <div className="container">
                {/* Service Wrap Start */}
                <div className="service-wrap-03">
                    <div className="row">
                        <div className="col-xl-3 col-md-6">
                            {/* Service Item Start */}
                            <div className="service-item-03">
                                <div className="service-img">
                                    <img src="images/ser-icon12.png" alt=""/>
                                </div>
                                <div className="service-content">
                                    <h3 className="title"><a href="/websolutions">Web & Application Development</a></h3>
                                    <p>Our skilled team utilizes cutting-edge technologies to design and build responsive, user-friendly websites and applications. With a focus on innovation and client satisfaction, we deliver robust, scalable, and modern applications.</p>
                                    <div className="read-more">
                                        <a href="/service"><i className="fas fa-plus"></i> Read More</a>
                                    </div>
                                </div>
                            </div>
                            {/* Service Item End */}
                        </div>
                        <div className="col-xl-3 col-md-6">
                            {/* Service Item Start */}
                            <div className="service-item-03">
                                <div className="service-img">
                                    <img src="images/ser-icon9.png" alt=""/>
                                </div>
                                <div className="service-content">
                                    <h3 className="title"><a href="/service">Support & Maintenance</a></h3>
                                    <p>Our business prioritizes the continual support and maintenance of our websites, applications, and API integration's. This involves proactive issue identification, debugging, and swift response to user inquiries.</p>
                                    <div className="read-more">
                                        <a href="/service"><i className="fas fa-plus"></i> Read More</a>
                                    </div>
                                </div>
                            </div>
                            {/* Service Item End */}
                        </div>
                        <div className="col-xl-3 col-md-6">
                            {/* Service Item Start */}
                            <div className="service-item-03">
                                <div className="service-img">
                                    <img src="images/ser-icon10.png" alt=""/>
                                </div>
                                <div className="service-content">
                                    <h3 className="title"><a href="/service">IT Consultancy & solution</a></h3>
                                    <p>As an IT consultancy, we specialize in providing customized technology solutions to meet our clients' unique needs. Leveraging our expertise, we analyze, recommend, and implement innovative IT strategies.</p>
                                    <div className="read-more">
                                        <a href="/service"><i className="fas fa-plus"></i> Read More</a>
                                    </div>
                                </div>
                            </div>
                            {/* Service Item End */}
                        </div>
                        <div className="col-xl-3 col-md-6">
                            {/* Service Item Start */}
                            <div className="service-item-03">
                                <div className="service-img">
                                    <img src="images/ser-icon11.png" alt=""/>
                                </div>
                                <div className="service-content">
                                    <h3 className="title"><a href="/apisolutions">API Integration</a></h3>
                                    <p>we streamline and optimize digital processes by seamlessly connecting diverse applications and systems. Our expertise lies in creating efficient and secure connections, facilitating real-time data exchange and enhancing overall system functionality.</p>
                                    <div className="read-more">
                                        <a href="/service"><i className="fas fa-plus"></i> Read More</a>
                                    </div>
                                </div>
                            </div>
                            {/* Service Item End */}
                        </div>
                    </div>
                </div>
                {/* Service Wrap End */}
            </div>
        </div>
    )
}

export default Service