const BlogTile = ({props}) => {
    return (
        <div className="col-lg-4 col-md-6">
            <div className="single-blog">
                <div className="blog-image">
                    <a href={`/${props.link}`}><img src={props.imgSmall} alt=""/></a>
                    <div className="top-meta">
                        <span className="date"><span>{props.day}</span>{props.month}</span>
                    </div>
                </div>
                <div className="blog-content">
                    <div className="blog-meta">
                        <span><i className="fas fa-user"></i> <a href="#">{props.author}</a></span>
                        <span><i className="far fa-comments"></i> 0 Comments</span>
                    </div>
                    <h3 className="title"><a href={`/${props.link}`}>{props.title}</a></h3>
                    <div className="blog-btn">
                        <a className="blog-btn-link" href={`/${props.link}`}>Read Full <i className="fas fa-long-arrow-alt-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogTile