import Register from "./Register/Register"
import SignIn from "./SignIn/SignIn"
import PageBanner from "../PageBanner/PageBanner"
import Alert from "../Alert/Alert"
import { useState } from "react"
import AuthSection from "./AuthSection"

const Login = () => {
    const [message,setMessage] = useState({display: false, error: false, text: "" })
    
    const breadcrumbItems = [
        { label: 'Home', link: '/' },
        { label: 'Login & Register' },
    ];

    return (
        <div className="main-wrapper" style={{marginBottom: "6em"}}>
            {/* Alert */}
            {message.display && <Alert type={message.error ? 'alert-danger' : 'alert-success'} text={message.text} />}
            {/* Page Banner */}
            <PageBanner backgroundImage="images/login-register.jpg" breadcrumbItems={breadcrumbItems} pageTitle="Login & Register" />
            {/* Login/Register Section */}
            <AuthSection>
                <SignIn message={message} setMessage={setMessage} />
                <Register message={message} setMessage={setMessage} />
            </AuthSection>

        </div>
    )
}

export default Login