const AiSolution = () => {
    return (
        <div className="main-wrapper">

        {/* Hero Start */}
        <div className="section techwix-hero-section-05">
            <div className="techwix-hero-wrap" style={{backgroundImage: "url(images/bg/hero-bg5.jpg)"}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            {/* Hero Content Start */}
                            <div className="hero-content">
                                <h3 className="sub-title">Lead your company into the age of AI</h3>
                                <h2 className="title">Take the next steps for an <span>AI-driven</span> enterprise.</h2>
                                <div className="hero-btn">
                                    <a className="btn" href="/about">Read More</a>
                                </div>
                            </div>
                            {/* Hero Content End */}
                        </div>
                        <div className="col-lg-6">
                            {/* Hero Image Start */}
                            <div className="hero-images">
                                <div className="images">
                                    <img src="images/ai-banner.png" alt=""/>
                                </div>
                            </div>
                            {/* Hero Image ennd */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Hero End */}

        {/* About Start */}
        <div className="section techwix-about-section-05 section-padding">
            <div className="container">
                {/* About Wrap Start */}
                <div className="about-wrap">
                    <div className="section-title text-center">
                        <h3 className="sub-title">Who we are</h3>
                        <h2 className="title">Providing cutting-edge AI solutions, adept at addressing practical challenges for businesses.</h2>
                    </div>
                    {/* About Content Wrap Start */}
                    <div className="about-content-wrap">
                        <div className="row">
                            <div className="col-lg-6">
                                {/* About Image Wrap Start */}
                                <div className="about-img-wrap">
                                    <div className="about-img about-img-big">
                                        <img src="images/ai-1.jpg" alt=""/>
                                    </div>
                                    <div className="about-img about-img-sm">
                                        <img src="assets/images/about-sm3.jpg" alt=""/>
                                        <div className="shape-01"></div>
                                    </div>
                                </div>
                                {/* About Image Wrap End */}
                            </div>
                            <div className="col-lg-6">
                                {/* About Content Start */}
                                <div className="about-content">
                                    <p className="title">Harness the potential of artificial intelligence through our pioneering solutions, crafted to optimize operations and enhance overall business performance. Employ advanced AI technologies to tackle intricate challenges and propel unparalleled success in the digital realm.</p>
                                    {/* <p className="text">Accelerate innovation with world-className tech teams We’ll match you to an entire remote team of incredible freelance talent for all your software development needs.</p> */}
                                    {/* About List Item Wrap Start */}
                                    <div className="about-list-item-wrap">
                                        <div className="about-list-item">
                                            <div className="about-img">
                                                <img src="assets/images/about-icon1.png" alt=""/>
                                            </div>
                                            <div className="about-text">
                                                <h3 className="title">Robotic Process Automation</h3>
                                                <p>Implement automation solutions that use AI to mimic human actions, streamlining repetitive tasks and improving operational efficiency.</p>
                                            </div>
                                        </div>
                                        <div className="about-list-item">
                                            <div className="about-img">
                                                <img src="assets/images/about-icon2.png" alt=""/>
                                            </div>
                                            <div className="about-text">
                                                <h3 className="title">AI-driven Customer Relationship Management (CRM)</h3>
                                                <p>Implement AI-powered tools to enhance customer relationship management, automate workflows, and improve customer engagement strategies.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* About List Item Wrap End */}
                                </div>
                                {/* About Content End */}
                            </div>
                        </div>
                    </div>
                    {/* About Content Wrap End */}
                </div>
                {/* About Wrap End */}
            </div>
        </div>
        {/* About End */}

        {/* Service Start */}
        <div className="section techwix-service-section-05" style={{marginBottom: "4em"}}>
            <div className="container">
                {/* Service Wrap Start */}
                <div className="service-wrap section-padding-02">
                    <div className="section-title text-center">
                        <h3 className="sub-title">Whats included in service</h3>
                        <h2 className="title">Building everything you need for your business</h2>
                    </div>
                    <div className="service-content-wrap">
                        <div className="row">
                            <div className="col-xl-3 col-sm-6">
                                {/* Service Item Start */}
                                <div className="service-item-04">
                                    <div className="service-img">
                                        <img src="images/ser-icon13.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="/service">AI base business solution</a></h3>
                                        <p>Leveraging advanced algorithms to analyze data, automate processes, and derive actionable insights.</p>
                                    </div>
                                </div>
                                {/* Service Item End */}
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                {/* Service Item Start */}
                                <div className="service-item-04">
                                    <div className="service-img">
                                        <img src="images/ser-icon14.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="/service">Chatbots & Virtual Assistants</a></h3>
                                        <p>AI-driven chatbot equipped with Real Meeting capabilities. Seamlessly schedule, organize, and coordinate physical or virtual meetings effortlessly.</p>
                                    </div>
                                </div>
                                {/* Service Item End */}
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                {/* Service Item Start */}
                                <div className="service-item-04">
                                    <div className="service-img">
                                        <img src="images/ser-icon15.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="/service">AI Integration and Consulting</a></h3>
                                        <p>Integrate AI solutions into existing software systems, along with strategic consulting to help businesses leverage AI for optimal outcomes.</p>
                                    </div>
                                </div>
                                {/* Service Item End */}
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                {/* Service Item Start */}
                                <div className="service-item-04">
                                    <div className="service-img">
                                        <img src="images/ser-icon16.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="/service">Robotic Process Automation (RPA)</a></h3>
                                        <p>Implement automation solutions that use AI to mimic human actions, streamlining repetitive tasks and improving operational efficiency.</p>
                                    </div>
                                </div>
                                {/* Service Item End */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Service Wrap End */}
            </div>
        </div>
        {/* Service End */}

    </div>
    )
}

export default AiSolution