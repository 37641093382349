import React from 'react';
import { loginFormInputs } from '../../../lib/tmpData';

const Form = ({ formData, setFormData, handleSubmit }) => {

    return (
        <div className="login-register-form">
            <form onSubmit={handleSubmit}>
                {loginFormInputs.map((input) => (
                    <div className="single-form" key={input.key}>
                        <input type={input.type} className="form-control" placeholder={input.placeholder}
                            onChange={(e) => setFormData({ ...formData, [input.key]: e.target.value })}
                        />
                    </div>
                ))}
                <div className="form-btn">
                    <button className="btn" type='submit'>Login</button>
                </div>
                <div className="single-form">
                    <p><a href="#">Lost your password?</a></p>
                </div>
            </form>
        </div>
    )
}

export default Form;