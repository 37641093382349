import { useState } from "react"
// import { useAuth } from "../../../contexts/AuthContext"
// import { useNavigate } from "react-router-dom";
import FormTitle from "../FormTitle"
import Form from "./Form"
import axios from "axios"


const SignIn = ({ message, setMessage }) => {
    // const { login } = useAuth(); // Import the login function from your AuthContext
    // const navigate = useNavigate(); // Get the navigate function
    const [formData,setFormData] = useState({ email: "", password: "" })
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            // const response = await axios.post(`${}`, formData);
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/auth/login`, formData);
            if (response.status === 200){
                const userData = response.data;
                // console.log(userData)
                if (userData.token) {
                    // Redirect to the dashboard using localStorage
                    window.location.href = userData.redirectsTo;
                }
                // login(userData);
                // navigate("/dashboard");
            } else {
                setMessage({ display: true, error: true, text: "Incorrect Username or Password..." });
                setTimeout(() => {
                  setMessage( (prevMessage) => ({ ...prevMessage, display: false }));
                }, 5000);
            }
        } catch (error) {
            setMessage({ display: true, error: true, text: "Incorrect Username or Password..." });
            setTimeout(() => {
              setMessage( (prevMessage) => ({ ...prevMessage, display: false }));
            }, 5000);
        }
    
        e.target.reset();
    }

    return (
        <div className="col-lg-6">
            <div className="login-register-box">
                <FormTitle title={"Login"} />
                <Form formData={formData} setFormData={setFormData} handleSubmit={handleSubmit}  />
            </div>
        </div>
    )
}

export default SignIn