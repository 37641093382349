const Cta = () => {
    return (
        <div className="section techwix-cta-section-02">
            <div className="container">
                {/* Cta Wrap Start */}
                <div className="cta-wrap" style={{backgroundImage: "url(images/bg/cta-bg.jpg)"}}>
                    <div className="row align-items-center">
                        <div className="col-xl-9 col-lg-8">
                            {/* Cta Content Start */}
                            <div className="cta-content">
                                <div className="cta-icon">
                                    <img src="images/cta-icon2.png" alt=""/>
                                </div>
                                <p>We’re Delivering the best customer Experience</p>
                            </div>
                            {/* Cta Content End */}
                        </div>
                        <div className="col-xl-3 col-lg-4">
                            {/* Cta Button Start */}
                            <div className="cta-btn">
                                <a className="btn btn-white" href="/">906 290 3767</a>
                            </div>
                            {/* Cta Button End */}
                        </div>
                    </div>
                </div>
                {/* Cta Wrap End */}
            </div>
        </div>
    )
}

export default Cta