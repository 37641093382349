import axios from "axios"
import { useState } from "react"
import Form from "./Form";
import FormTitle from "../FormTitle";

const Register = ({ message, setMessage }) => {
    const [formData,setFormData] = useState({ first_name: "" }, { last_name: "" }, { email: "" }, { password: "" })

    const showMessage = (error, text) => {
        setMessage({ display: true, error, text });
        setTimeout(() => {
          setMessage( (prevMessage) => ({ ...prevMessage, display: false }));
        }, 5000);
      }

      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/users/new`, formData);
    
          if (response.status === 200) showMessage(false, 'You will receive an email to complete your registration. (Email may be in junk)')
          else showMessage(true, 'Something went wrong, please try again or contact support@g-2technologies.com')

        } catch (error) {
          showMessage(true, 'Something went wrong, please try again or contact support@g-2technologies.com');
        }
    
        e.target.reset();
      }

    return (
      <div className="col-lg-6">
        <div className="login-register-box">
          <FormTitle title={"Register"} />
          <Form formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} />
        </div>
      </div>
    )
}

export default Register