const About = () => {
    return (
        <div className="section techwix-about-section-04 section-padding">
            <div className="shape-1"></div>
            <div className="container">
                {/* About Wrapper Start */}
                <div className="about-wrap">
                    <div className="row">
                        <div className="col-lg-6">
                            {/* About Content Wrap Start */}
                            <div className="about-content-wrap">
                                <div className="section-title">
                                    <h3 className="sub-title">Who we are</h3>
                                    <h2 className="title">Unleashing Potential through Expert Software Development, Management & Support Services.</h2>
                                </div>
                                <p className="text">Our highly tailored IT design, management, and support services are crafted to meet the specific requirements of each client. With a focus on precision and innovation, we provide comprehensive solutions that seamlessly integrate into existing infrastructures, ensuring optimal performance and reliability.</p>
                                {/* About Author Info Wrap Start */}
                                <div className="about-author-info-wrap">
                                    <div className="about-author">
                                        <img src="images/sign.png" alt="" style={{width: "15em"}} />
                                        <h3 className="name">Keifer Rasner</h3>
                                        <span className="designation">Lead Developer, G2 Technologies</span>
                                    </div>
                                    <div className="about-info">
                                        <p>Call to ask any question </p>
                                        <h3 className="number">906-290-3767</h3>
                                    </div>
                                </div>
                                {/* About Author Info Wrap End */}
                            </div>
                            {/* About Content Wrap End */}
                        </div>
                        <div className="col-lg-6">
                            {/* About Image Wrap Start */}
                            <div className="about-img-wrap">
                                <div className="about-img about-img-big">
                                    <img src="images/home-about.jpg" alt=""/>    
                                </div>
                            </div>
                            {/* About Image Wrap End */}
                        </div>
                    </div>
                </div>
                {/* About Wrapper End */}
            </div>
        </div>
    )
}

export default About