import React, { useState } from 'react';
import axios from 'axios';
const ContactForm = () => {
    const [formData, setFormData] = useState({ name: "" }, { email: "" }, { subject: ""}, { message: ""})

    const sendEmail = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/contact/contactform`,formData)
            window.location.reload()
        } catch (error) {
            console.log(error)
            window.location.reload()   
        }
        
    }

    return (
        <div className="col-xxl-7 col-lg-6">
            {/* Contact Form Start */}
            <div className="contact-form">
                <div className="contact-form-wrap">
                    <div className="heading-wrap text-center">
                        <span className="sub-title">Leave us massage</span>
                        <h3 className="title">How May We Help You!</h3>
                    </div>
                    <form action="#">
                        <div className="row">
                            <div className="col-sm-6">
                                {/* Single Form Start */}
                                <div className="single-form">
                                    <input type="text" placeholder="Name *" onChange={(e) => setFormData({...formData, name: e.target.value })} />
                                </div>
                                {/* Single Form End */}
                            </div>
                            <div className="col-sm-6">
                                {/* Single Form Start */}
                                <div className="single-form">
                                    <input type="email" placeholder="Email *" onChange={(e) => setFormData({...formData, email: e.target.value })} />
                                </div>
                                {/* Single Form End */}
                            </div>
                            <div className="col-sm-12">
                                {/* Single Form Start */}
                                <div className="single-form">
                                    <input type="text" placeholder="Subject *" onChange={(e) => setFormData({...formData, subject: e.target.value })} />
                                </div>
                                {/* Single Form End */}
                            </div>
                            <div className="col-sm-12">
                                {/* Single Form Start */}
                                <div className="single-form">
                                    <textarea placeholder="Write A Message" onChange={(e) => setFormData({...formData, message: e.target.value })} ></textarea>
                                </div>
                                {/* Single Form End */}
                            </div>
                            <div className="col-sm-12">
                                {/*  Single Form Start */}
                                <div className="form-btn">
                                    <button className="btn btn-3" type="submit" onClick={sendEmail}>Send Message</button>
                                </div>
                                {/*  Single Form End */}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Contact Form End */}
        </div>        
    )
}

export default ContactForm