const ChooseUs = () => {
    return (
        <div className="section techwix-choose-us-section section-padding" style={{backgroundImage: "url(images/bg/choose-us-bg.jpg)"}}>
            <div className="container">
                {/* Choose Us Wrap Start */}
                <div className="choose-us-wrap">
                    <div className="section-title text-center">
                        <h3 className="sub-title">REASON TO CHOOSE US</h3>
                        <h2 className="title">Elevate Your Digital Presence with Expert Software Solutions.</h2>
                    </div>
                    <div className="choose-us-content-wrap">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item">
                                    <div className="choose-us-img">
                                        <a href="choose-us.html"><img src="images/reason-1.jpg" alt=""/></a>
                                        <div className="choose-us-content">
                                            <h3 className="title">Scalable Solutions</h3>
                                            <p>Providing dynamic and adaptable software architectures that grow with your business needs. We prioritize scalability, ensuring our solutions can seamlessly expand to handle increased demands without compromising performance.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item">
                                    <div className="choose-us-img">
                                        <a href="choose-us.html"><img src="images/reason-2.jpg" alt=""/></a>
                                        <div className="choose-us-content">
                                            <h3 className="title">Expertise and Innovation</h3>
                                            <p>Benefit from our team's deep expertise in cutting-edge technologies, ensuring innovative solutions that meet the evolving demands of the digital landscape.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                {/* Choose Us Item Start */}
                                <div className="choose-us-item">
                                    <div className="choose-us-img">
                                        <a href="choose-us.html"><img src="images/reason-3.jpg" alt=""/></a>
                                        <div className="choose-us-content">
                                            <h3 className="title">Multifunctional Technology</h3>
                                            <p>We design software with a comprehensive approach, integrating various functions to streamline operations and enhance efficiency.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Choose Us Item End */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="more-choose-content text-center">
                                    <p>Learn more about <a href="/about">More reason <i className="fas fa-long-arrow-alt-right"></i></a> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Choose Us Wrap End */}
            </div>
        </div>
    )
}

export default ChooseUs