import React from "react";
import Packages from "./Packages";

const Payments = () => {

  return (
    <div className="solutions-container" style={{minHeight: "100vh", marginTop: "8em"}}>
      <Packages />
    </div>
  );
}

export default Payments