import axios from "axios"
import { useEffect, useState } from "react"

const Packages = () => {
    const [products, setProducts] = useState([])
    useEffect(() => {
        async function fetchProducts() {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/products`)
            setProducts(response.data)
        }
        fetchProducts()
    },[])
    return (
        <div className="section techwix-pricing-section section-padding-02" style={{marginBottom: "10em"}}>
            <div className="container">
            <h1 style={{justifyContent: "center"}}>Choose Your Software Package</h1>
                {/* Pricing Wrap Start */}
                <div className="pricing-wrap">
                    <div className="pricing-content-wrap">
                        <div className="row">
                            {products && products.map(product => (
                                <div className="col-lg-4 col-md-6" key={product._id}>
                                    {/* Single Pricing Start */}
                                    <div className={product.subscription ? 'active single-pricing' : "single-pricing"}>
                                        <div className="pricing-badge">
                                            <span className="title">{product.name}</span>
                                        </div>
                                        <div className="pricing-price">
                                            {product.subscription ? <span className="currency">$</span> : <span className="currency" style={{marginBottom: "50px"}}>$</span> }
                                            {product.subscription ? <h3 className="price">{product.priceInCents / 100}<span>/Month</span></h3> : <h2 className="price">{product.priceInCents / 100}<br/><span> One time payment</span></h2>}
                                        </div>
                                        <div className="pricing-content">
                                            <div className="pricing-btn">
                                                <button className="btn" onClick={() => {
                                                    fetch(`${process.env.REACT_APP_SERVER_BASE}/payment/create-checkout-session`, {
                                                        method: 'POST',
                                                        headers: {
                                                            "Content-Type": "application/json"
                                                        },
                                                        body: JSON.stringify({
                                                            "productId": product._id
                                                        })
                                                    }).then(res => {
                                                        if (res.ok) return res.json()
                                                        return res.json().then(json => Promise.reject(json))
                                                    }).then(({ url }) => {
                                                        window.location = url
                                                    }).catch(e => {
                                                        console.error(e.error)
                                                    })
                                                }}>Checkout</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Single Pricing End */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* Pricing Wrap End */}

            </div>
        </div>
    )
}

export default Packages