export const blogData = [
    { 
        id: 1,
        day: "28",
        month: "Nov",
        fullDate: "November 28, 2023",
        imgXXS: "images/blog-1-xxs.jpg",
        imgXS: "images/blog-1-xs.jpg",
        imgSmall: "images/blog-1-sm.jpg",
        imgBig: "images/blog-1-bg.jpg",
        author: "Keifer Rasner",
        link: "trends-and-strategies-in-modern-web-development",
        title: "Trends and Strategies in Modern Web Development",
        blockQuote: "Amidst the ever-evolving landscape of web development, staying informed on modern trends isn't just a choice; it's a strategic imperative. Join me on a journey through the dynamic interplay of innovation and strategy, where the key to success lies in understanding and embracing the pulse of the digital realm.",
        details: [
            {
                header: true,
                headerText: "Headless Architectures: Unleashing Flexibility", 
                text: "Modern web development is witnessing a shift towards headless architectures, decoupling the front-end and back-end for enhanced flexibility. We delve into how this approach empowers developers to choose the best tools for each aspect, fostering agility in building robust and scalable web applications."
            },
            {
                header: true,
                headerText: "Responsive Web Design: Adapting to Every Device",
                text: "In an era where users access the web across various devices, responsive web design remains paramount. We explore the importance of creating seamless user experiences across desktops, tablets, and smartphones. Learn how our strategies ensure that your website adapts gracefully to the diversity of screen sizes and resolutions."
            },
            {
                header: true,
                headerText: "Progressive Web Applications (PWAs): Bridging the Gap Between Web and Mobile",
                text: "Progressive Web Applications (PWAs) continue to revolutionize user engagement by providing app-like experiences within web browsers. We dissect the trends and strategies behind PWAs, showcasing how they enhance performance, accessibility, and user retention."
            },
            {
                header: true,
                headerText: "Artificial Intelligence (AI) Integration: Elevating User Interactions",
                text: "The integration of Artificial Intelligence (AI) is transforming web development, from chatbots offering personalized interactions to machine learning algorithms optimizing user experiences. Explore how our strategies leverage AI to create dynamic and intelligent web applications that adapt to user behaviors."
            },
            {
                header: true,
                headerText: "Serverless Architectures: Streamlining Development and Deployment",
                text: "Serverless architectures are reshaping the development and deployment landscape, offering scalability and cost efficiency. We discuss the trends and strategies associated with serverless computing, showcasing how it simplifies the development process and allows for seamless scaling based on demand."
            },
            {
                header: true,
                headerText: "Jamstack: Revolutionizing Web Architecture",
                text: "Jamstack (JavaScript, APIs, and Markup) is gaining prominence for its ability to create fast and secure web applications. We unravel the components of Jamstack architecture and demonstrate how it enhances performance, security, and developer experience."
            },
            {
                header: true,
                headerText: "Accessibility and Inclusive Design: Ensuring Universal User Experiences",
                text: "The trend towards accessibility and inclusive design is imperative for creating web applications that cater to diverse user needs. We explore the strategies employed to ensure that websites are not only visually appealing but also accessible to users with varying abilities."
            },
            {
                header: true,
                headerText: "Conclusion: Navigating the Future of Web Development",
                text: "In conclusion, navigating the digital frontier of web development requires staying abreast of emerging trends and adopting strategic approaches. At our software company, we are committed to not only embracing these trends but also crafting strategies that empower our clients to thrive in the ever-evolving digital landscape. Join us on this journey of exploration, where trends meet strategies, and the future of web development unfolds."
            }
        ],
        para: "The digital frontier is ever-evolving, and web development trends shape the landscape. This blog serves as your compass, guiding you through the latest trends and strategies in modern web development. From the rise of headless architectures to the impact of artificial intelligence, stay informed about the tools and techniques that can propel your website into the future." 
    },
    { 
        id: 2,
        day: "14",
        month: "Nov",
        fullDate: "November 14, 2023",
        imgXXS: "images/blog-2-xxs.jpg",
        imgXS: "images/blog-2-xs.jpg",
        imgSmall: "images/blog-2-sm.jpg",
        imgBig: "images/blog-2-bg.jpg",
        author: "Keifer Rasner",
        link: "redefining-the-online-presence",
        title: "Redefining the Online Presence",
        blockQuote: "In the digital landscape, we believe in more than just websites; we craft immersive online experiences that redefine your brand's presence.",
        details: [
            { 
                header: true, 
                headerText: "Understanding the Uniqueness of Your Brand:",
                text: "The journey toward redefining your online presence begins with a deep dive into understanding the uniqueness of your brand. Through collaborative discussions and in-depth research, we aim to unravel the essence of your business, its values, and the message you wish to convey. By grasping these foundational aspects, we lay the groundwork for a web development strategy that authentically represents your brand identity."
            },
            { 
                header: true, 
                headerText: "Crafting User-Centric Experiences:",
                text: "User-centricity is at the core of effective web development. We explore the art and science of user interface (UI) and user experience (UX) design, ensuring that every click on your website is an intuitive and enjoyable experience. From seamless navigation to visually captivating design elements, our goal is to create a digital environment that not only engages but leaves a positive and lasting impression on your visitors."
            },
            { 
                header: true, 
                headerText: "Tailoring Solutions for Your Business Needs:",
                text: "One size does not fit all in web development. We believe in tailoring solutions to address the specific needs and goals of your business. Whether you're launching an e-commerce platform, a corporate website, or a blog, our approach is customized to align with your objectives. We explore the integration of features and functionalities that enhance the user experience while meeting the practical requirements of your industry."
            },
            { 
                header: true, 
                headerText: "Optimizing for Search Engines and Performance:",
                text: "An effective online presence extends beyond aesthetics. We delve into the technical aspects of web development, optimizing your website for search engines and performance. Through meticulous coding practices, responsive design, and attention to loading speeds, we ensure that your website not only looks great but also performs optimally, contributing to higher search rankings and improved user satisfaction."
            },
            { 
                header: true, 
                headerText: "Ensuring Scalability for Future Growth:",
                text: "The digital landscape is ever-evolving, and we design with the future in mind. Our web development solutions are crafted to be scalable, allowing your online presence to grow seamlessly as your business expands. Whether it's accommodating increased traffic, adding new features, or adapting to technological advancements, we build a foundation that can evolve with your business."
            },
            { 
                header: true, 
                headerText: "Conclusion:",
                text: "Redefining your online presence is not just about having a website; it's about creating a digital space that reflects your brand, engages your audience, and stands the test of time. At our software company, we are passionate about guiding you through this journey, tailoring solutions that go beyond industry standards to create a truly impactful and memorable online presence. Join us as we redefine the online experience, one tailored solution at a time."
            }
        ],
        para: "In the dynamic world of the internet, your online presence serves as a crucial gateway for connecting with audiences and making a lasting impression. At our software company, we recognize the significance of this digital landscape and are dedicated to redefining online experiences through effective web development solutions tailored to the unique needs of each client."
    },
    { 
        id: 3,
        day: "16",
        month: "Oct",
        fullDate: "October 16, 2023",
        imgXXS: "images/blog-3-xxs.jpg",
        imgXS: "images/blog-3-xs.jpg",
        imgSmall: "images/blog-3-sm.jpg",
        imgBig: "images/blog-3-bg.jpg",
        author: "Keifer Rasner", 
        link: "from-concept-to-clicks",
        title: "From Concept to Clicks",
        blockQuote: "In the realm of web development, the journey from concepts to clicks is a transformative odyssey where ideas crystallize into seamless digital experiences. Our software company specializes in navigating this path, employing cutting-edge technologies and meticulous methodologies to craft websites that not only meet but exceed the expectations of scalability, responsiveness, and user engagement.",
        details: [
            {
                header: false,
                text: "Embarking on the journey from concept to clicks in web development is akin to navigating a fascinating landscape of digital possibilities. At our software company, we believe in demystifying this process and shedding light on the intricate steps that lead to a seamless and scalable online presence.",
            },
            {
                header: false,
                text: "The journey begins with the conceptualization phase, where collaboration and understanding lay the groundwork for success. We explore how ideas are translated into a tangible roadmap, aligning technology with the unique goals and audience of our clients. This initial phase is crucial, setting the stage for a development process that prioritizes not just coding but the creation of a robust foundation ready for growth.",
            },
            {
                header: false,
                text: "Seamlessness is at the core of our approach, and we delve into the intricacies of user interface (UI) and user experience (UX) design. From intuitive navigation to visually appealing aesthetics, we aim to captivate and engage users at every click. Our commitment to scalability extends beyond the present, with a focus on creating websites that can adapt and evolve as businesses grow.", 
            },
            {
                header: false,
                text: "Our exploration delves into the integration of cutting-edge technologies, demystifying concepts like responsive design, efficient databases, and scalable architectures. Through real-world examples and case studies, we aim to empower users with insights into how these elements work together to create web solutions that withstand the tests of growth and changing digital landscapes.",
            },
            {
                header: false,
                text: "This educational journey is not just about showcasing our approach; it's about sharing knowledge and empowering you with a deeper understanding of the web development process. As we navigate from concept to clicks, our goal is to illuminate the intricacies of crafting websites, making the digital landscape more accessible and the journey from idea to implementation more comprehensible. Join us on this exploration where concepts become clicks, and the path to a vibrant online reality becomes clearer.", 
            }
        ],
        para: "Whether you're launching a new site or revamping an existing one, discover how our software company can turn your vision into a digital reality."
    },
]

export const termsAndConditions = [
    {
      title: "Acceptance of Terms",
      text:
        "By accessing or using any services provided by G2 Technologies, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not use our services.",
    },
    {
      title: "License",
      text:
        "G2 Technologies grants you a non-exclusive, non-transferable license to use our software in accordance with these terms. This license is revocable at any time without notice and with or without cause.",
    },
    {
      title: "User Responsibilities",
      text:
        "You are responsible for maintaining the confidentiality of your account and password. You agree to notify G2 Technologies immediately of any unauthorized use of your account.",
    },
    {
      title: "Use of Services",
      text:
        "You agree to use G2 Technologies' services only for lawful purposes and in a manner consistent with all applicable local, national, and international laws and regulations.",
    },
    {
      title: "Intellectual Property",
      text:
        "All content and materials available on G2 Technologies' website, including but not limited to text, graphics, logos, button icons, images, audio clips, and software, are the property of G2 Technologies and are protected by applicable intellectual property laws.",
    },
    {
      title: "Privacy Policy",
      text:
        "Your use of G2 Technologies' services is also governed by our Privacy Policy, which is incorporated by reference into these Terms and Conditions. Please review the Privacy Policy to understand our practices.",
    },
    {
      title: "Warranty Disclaimer",
      text:
        "G2 Technologies makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.",
    },
    {
      title: "Limitation of Liability",
      text:
        "In no event shall G2 Technologies or its suppliers be liable for any damages arising out of the use or inability to use the software or services, even if G2 Technologies or a G2 Technologies authorized representative has been notified orally or in writing of the possibility of such damage.",
    },
    {
      title: "Changes to Terms",
      text:
        "G2 Technologies reserves the right to revise these Terms and Conditions at any time without notice. By using our services, you are agreeing to be bound by the then-current version of these terms.",
    },
    {
      title: "Governing Law",
      text:
        "These terms and conditions are governed by and construed in accordance with the laws of [Your Country/State], and you irrevocably submit to the exclusive jurisdiction of the courts in that location.",
    },
    {
      title: "Questions",
      text:
        "Contact Us: support@g-2technologies.com",
    },
];

export const registrationFormInputs = [
    { type: 'text', placeholder: 'Email', key: 'email_new' },
    { type: 'text', placeholder: 'First Name', key: 'first_name' },
    { type: 'text', placeholder: 'Last Name', key: 'last_name' },
    { type: 'password', placeholder: 'Password', key: 'password_new' },
];

export const loginFormInputs = [
    { type: 'text', placeholder: 'Username or Email', key: 'email' },
    { type: 'password', placeholder: 'Password', key: 'password' },
];