import ContactForm from "./ContactForm"

const WebAppSolutions = () => {

    return (
        <div className="main-wrapper">

            {/* Hero Start */}
            <div className="section techwix-hero-section-02" style={{backgroundImage: "url(images/websolutions-banner.jpg)"}}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            {/* Hero Content Start */}
                            <div className="hero-content">
                                <h3 className="sub-title" >We provide the most advanced digital solution</h3>
                                <h2 className="title" >Crafting Intelligent Solutions Today</h2>
                            </div>
                            {/* Hero Content End */}
                        </div>
                    </div>
                </div>
                <div className="svg-shape">
                    <svg xmlns="http://www.w3.org/2000/svg" width="2695px" height="349px">
                        <path fill-rule="evenodd" fill="rgb(255, 255, 255)" d="M400.0,36.999 C479.999,88.625 899.487,302.793 1925.999,42.999 C2230.335,-34.22 2564.914,16.158 2564.914,16.158 C2564.914,16.158 2649.752,197.299 2691.999,240.999 C2795.999,348.575 0.0,348.999 0.0,348.999 C0.0,348.999 320.0,-14.625 400.0,36.999 Z" />
                    </svg>
                </div>
            </div>
            {/* Hero End */}

            {/* About Start */}
            <div className="section techwix-about-section-03 section-padding-02">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            {/* About Content Start */}
                            <div className="about-content-03">
                                <div className="section-title">
                                    <h3 className="sub-title color-2">Who we are</h3>
                                    <h2 className="title">Behind the Code: Defining Excellence in Software Development</h2>
                                </div>
                                <p className="text">We are the architects of digital innovation, shaping cutting-edge solutions with a commitment to excellence.</p>
                                <div className="about-quote">
                                    <blockquote className="blockquote">
                                        <p>With a passionate team of developers and a focus on forward-thinking technology, we bring your vision to life, transforming ideas into impactful software solutions.</p>
                                    </blockquote>
                                </div>
                                {/* About List Start */}
                                {/* <div className="about-list-02">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="about-list-item-02">
                                                <h3 className="title"><i className="fas fa-arrow-circle-right"></i> Expert Team</h3>
                                                <p>Accelerate innovation with world-className tech teams</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="about-list-item-02">
                                                <h3 className="title"><i className="fas fa-arrow-circle-right"></i>Custom Code</h3>
                                                <p>Accelerate innovation with world-className tech teams</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* About List End */}
                            </div>
                            {/* About Content End */}
                        </div>
                        <div className="col-lg-6">
                            {/* About Image Warp Start */}
                            <div className="about-img-warp-3">
                                <div className="shape-1"></div>
                                <div className="about-img about-img-big">
                                    <img src="images/behind-code.jpg" alt=""/>
                                </div>
                                {/* <div className="about-img about-img-sm">
                                    <img src="images/about-sm.jpg" alt=""/>
                                </div> */}
                            </div>
                            {/* About Image Warp End */}
                        </div>
                    </div>
                </div>
            </div>
            {/* About End */}

            {/* Service Start */}
            <div className="section techwix-service-section-02 section-padding" style={{backgroundImage: "url(images/bg/service-bg.jpg)"}}>
                <div className="container">
                    {/* Service Wrap Start */}
                    <div className="service-wrap">
                        <div className="section-title text-center">
                            <h3 className="sub-title color-2">What we provide</h3>
                            <h2 className="title">We provide truly prominent Software solutions.</h2>
                        </div>
                        {/* Service Content Wrap Start */}
                        <div className="service-content-wrap">
                            <div className="row">
                                <div className="col-xl-3 col-sm-6">
                                    {/* Service Item Start */}
                                    <div className="service-item-02">
                                        <div className="service-img">
                                            <img src="images/ser-icon5.png" alt=""/>
                                        </div>
                                        <div className="next-icon">
                                            <img src="images/next-icon.png" alt=""/>
                                        </div>
                                        <div className="service-content">
                                            <h3 className="title"><a href="/service">Web <br/> Development</a></h3>
                                            <p>Whether you have an existing vision or starting from scratch. We will help craft a scalable and reliable solution.</p>
                                        </div>
                                    </div>
                                    {/* Service Item End */}
                                </div>
                                <div className="col-xl-3 col-sm-6">
                                    {/* Service Item Start */}
                                    <div className="service-item-02 service-2">
                                        <div className="service-img">
                                            <img src="images/ser-icon6.png" alt=""/>
                                        </div>
                                        <div className="next-icon">
                                            <img src="images/next-icon.png" alt=""/>
                                        </div>
                                        <div className="service-content">
                                            <h3 className="title"><a href="/service">Mobile <br/> App Development</a></h3>
                                            <p>Utilizing the latest technologies to design and deliver custom mobile solutions, ensuring seamless functionality and optimal performance across diverse platforms.</p>
                                        </div>
                                    </div>
                                    {/* Service Item End */}
                                </div>
                                <div className="col-xl-3 col-sm-6">
                                    {/* Service Item Start */}
                                    <div className="service-item-02">
                                        <div className="service-img">
                                            <img src="images/ser-icon7.png" alt=""/>
                                        </div>
                                        <div className="next-icon">
                                            <img src="images/next-icon.png" alt=""/>
                                        </div>
                                        <div className="service-content">
                                            <h3 className="title"><a href="/service">API <br/> Integration</a></h3>
                                            <p>Connect diverse systems and applications, fostering streamlined data exchange and enhanced functionality.</p>
                                        </div>
                                    </div>
                                    {/* Service Item End */}
                                </div>
                                <div className="col-xl-3 col-sm-6">
                                    {/* Service Item Start */}
                                    <div className="service-item-02 service-4">
                                        <div className="service-img">
                                            <img src="images/ser-icon8.png" alt=""/>
                                        </div>
                                        <div className="next-icon">
                                            <img src="images/next-icon.png" alt=""/>
                                        </div>
                                        <div className="service-content">
                                            <h3 className="title"><a href="/service">AI <br/> Solutions</a></h3>
                                            <p>Allow us to help your company lead the way in a world powered by Artificial Intelligence.</p>
                                        </div>
                                    </div>
                                    {/* Service Item End */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="more-service-content text-center">
                                        <p>Learn more about <a href="/service">More Services <i className="fas fa-long-arrow-alt-right"></i></a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Service Content Wrap End */}
                    </div>
                    {/* Service Wrap End */}
                </div>
            </div>
            {/* Service End */}


            {/* Contact Start */}
            <div className="section techwix-contact-section section-padding">
                <div className="container">
                    {/* Contact Wrap Start */}
                    <div className="contact-wrap" style={{backgroundImage: "url(images/shape/contact-shape.png)"}}>
                        <div className="row">
                            <div className="col-xxl-5 col-lg-6">
                                {/* Contact Info Start */}
                                <div className="contact-info">
                                    <div className="section-title">
                                        <h2 className="title">To make requests for further information, contact us</h2>
                                    </div>
                                    <ul>
                                        <li>
                                            {/* Contact Info Item Start */}
                                            <div className="contact-info-item d-flex align-items-center">
                                                <div className="contact-info-icon">
                                                    <i className="flaticon-phone-call"></i>
                                                </div>
                                                <div className="contact-info-text">
                                                    <h4 className="title">Contact Number</h4>
                                                    <p>906 290 3767</p>
                                                </div>
                                            </div>
                                            {/* Contact Info Item End */}
                                        </li>
                                        <li>
                                            {/* Contact Info Item Start */}
                                            <div className="contact-info-item d-flex align-items-center">
                                                <div className="contact-info-icon">
                                                    <i className="flaticon-email"></i>
                                                </div>
                                                <div className="contact-info-text">
                                                    <h4 className="title">Our Mail</h4>
                                                    <p>krasner@g-2technologies.com</p>
                                                </div>
                                            </div>
                                            {/* Contact Info Item End */}
                                        </li>
                                    </ul>
                                </div>
                                {/* Contact Info End */}
                            </div>
                            <ContactForm />
                        </div>
                    </div>
                    {/* Contact Wrap End */}
                </div>
            </div>
            {/* Contact End */}

    </div>   
    )
}

export default WebAppSolutions