import axios from 'axios';
import React, { useState } from 'react';

const EmailForm = () => {
    const [formData, setFormData] = useState({ name: "" }, { email: "" }, { subject: ""}, { message: ""})

    const sendEmail = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/contact/contactform`,formData)
            window.location.reload()
        } catch (error) {
            console.log(error)
            window.location.reload()   
        }
    }
    
      return (
        <div className="section techwix-contact-section techwix-contact-section-02 techwix-contact-section-03 section-padding-02" style={{marginBottom: "10em"}}>
            <div className="container">
                {/* Contact Wrap Start */}
                <div className="contact-wrap">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            {/* Contact Form Start */}
                            <div className="contact-form">
                                <div className="contact-form-wrap">
                                    <div className="heading-wrap text-center">
                                        <span className="sub-title"> request a quote</span>
                                        <h3 className="title">How May We Help You!</h3>
                                    </div>
                                    <form action="#">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                {/* Single Form Start */}
                                                <div className="single-form">
                                                    <input type="text" placeholder="Name *" onChange={(e) => setFormData({...formData, name: e.target.value })} />
                                                </div>
                                                {/* Single Form End */}
                                            </div>
                                            <div className="col-sm-6">
                                                {/* Single Form Start */}
                                                <div className="single-form">
                                                    <input type="email" placeholder="Email *" onChange={(e) => setFormData({...formData, email: e.target.value })} />
                                                </div>
                                                {/* Single Form End */}
                                            </div>
                                            <div className="col-sm-12">
                                                {/* Single Form Start */}
                                                <div className="single-form">
                                                    <input type="text" placeholder="Subject *" onChange={(e) => setFormData({...formData, subject: e.target.value })} />
                                                </div>
                                                {/* Single Form End */}
                                            </div>
                                            <div className="col-sm-12">
                                                {/* Single Form Start */}
                                                <div className="single-form">
                                                    <textarea placeholder="Write A Message" onChange={(e) => setFormData({...formData, message: e.target.value })}></textarea>
                                                </div>
                                                {/* Single Form End */}
                                            </div>
                                            <div className="col-sm-12">
                                                {/*  Single Form Start */}
                                                <div className="form-btn">
                                                    <button className="btn" type="submit" onClick={sendEmail}>Send Message</button>
                                                </div>
                                                {/*  Single Form End */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* Contact Form End */}
                        </div>
                    </div>
                </div>
                {/* Contact Wrap End */}
            </div>
        </div>
    )
}

export default EmailForm

