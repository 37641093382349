import { termsAndConditions } from "../../lib/tmpData"
import SingleTerm from "./SingleTerm"

const Terms = () => {
    return (
        <div className="main-wrapper" style={{marginBottom: "5em"}}>
            <div className="container" style={{minHeight: "100vh", paddingTop: "12em"}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "4em", marginTop: "2em", paddingBottom: "1em", borderBottom: "1px solid #e3e3e5"}} >
                    <h2 className="title">Terms & Conditions</h2>
                </div>
                {termsAndConditions.map((term,i) => <SingleTerm props={term} key={i} />)}
            </div>
        </div>
    )
}

export default Terms