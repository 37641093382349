const ApiSolution = () => {
    return (
        <div className="main-wrapper" style={{overflowX: "hidden"}}>

        {/* Hero Start */}
        <div className="section techwix-hero-section-06" style={{backgroundImage: "url(images/bg/hero-bg6.jpg)"}}>

            <div className="container">
                <div className="row flex-column-reverse flex-lg-row">
                    <div className="col-lg-6">
                        {/* Hero Image Start */}
                        <div className="hero-images">
                            <img className="shape-1" src="images/shape/hero-shape1.png" alt=""/>
                        </div>
                        {/* Hero Image ennd */}
                    </div>
                    <div className="col-lg-6" style={{marginBottom: "2em"}}>
                        {/* Hero Content Start */}
                        <div className="hero-content">
                            <h3 className="sub-title">Seamless API Solutions</h3>
                            <h2 className="title">Unleash the Power of <span>API Integration</span> for Enhanced Connectivity and Functionality</h2>
                            <div className="hero-btn">
                                <a className="btn btn-4" href="/about">Read More</a>
                            </div>
                        </div>
                        {/* Hero Content End */}
                    </div>
                </div>
            </div>
        </div>
        {/* Hero End */}

        {/* Features Start */}
        <div className="section techwix-features-section-02" style={{marginTop: "10em"}}>
            <div className="container">
                {/* Features Wrap Start */}
                <div className="features-wrap-02">
                    <div className="row">
                        <div className="col-lg-6">
                            {/* Features Item Start */}
                            <div className="features-item">
                                <div className="features-icon">
                                    <img src="assets/images/feat-4.png" alt=""/>
                                </div>
                                <div className="features-content">
                                    <h3 className="title"><a href="#">Custom API Solutions</a></h3>
                                    <p>Tailor APIs to specific business needs, ensuring they align with the unique requirements of your software solutions.</p>
                                    <a className="lern-more" href="/service">Learn More <i className="fas fa-long-arrow-alt-right"></i></a>
                                </div>
                            </div>
                            {/* Features Item End */}
                        </div>
                        <div className="col-lg-6">
                            {/* Features Item Start */}
                            <div className="features-item">
                                <div className="features-icon">
                                    <img src="assets/images/feat-5.png" alt=""/>
                                </div>
                                <div className="features-content">
                                    <h3 className="title"><a href="#">JSON/XML Data Parsing</a></h3>
                                    <p>Develop APIs that efficiently handle JSON or XML data formats, ensuring smooth interoperability across diverse applications.</p>
                                    <a className="lern-more" href="/service">Learn More <i className="fas fa-long-arrow-alt-right"></i></a>
                                </div>
                            </div>
                            {/* Features Item End */}
                        </div>
                    </div>
                </div>
                {/* Features Wrap End */}
            </div>
        </div>
        {/* Features End */}

        {/* About Start */}
        <div className="section techwix-about-section-06 section-padding-03">
            <div className="shape-1"></div>
            <div className="container">
                {/* About Wrapper Start */}
                <div className="about-wrap">
                    <div className="row">
                        <div className="col-lg-6">
                            {/* About Image Start */}
                            <div className="about-img">
                                {/* <img className="shape-1" src="images/shape/about-shape1.png" alt=""/> */}
                                <div className="image">
                                    <img src="images/api-solutions.jpg" alt=""/>
                                </div>
                            </div>
                            {/* About Image End */}
                        </div>
                        <div className="col-lg-6">
                            {/* About Content Wrap Start */}
                            <div className="about-content-wrap">
                                <div className="section-title">
                                    <h3 className="sub-title color-3">Who we are</h3>
                                    <h2 className="title"> API Solutions for Enhanced Integration and Functionality</h2>
                                </div>
                                <p className="text">API solutions designed to seamlessly integrate diverse functionalities and foster a connected digital ecosystem. From robust third-party integrations to custom API development, empower your applications with the versatility and power of our connectivity solutions.</p>
                                {/* About List Wrap Start */}
                                <div className="about-list-wrap">
                                    <ul className="about-list">
                                        <li className="list"><a href="#">Scalable Solutions <i className="fas fa-long-arrow-alt-right"></i></a></li>
                                        <li className="list"><a href="#">Comprehensive Integration<i className="fas fa-long-arrow-alt-right"></i></a></li>
                                        <li className="list"><a href="#">Elevated Functionality<i className="fas fa-long-arrow-alt-right"></i></a></li>
                                    </ul>
                                </div>
                                {/* About List Wrap End */}
                            </div>
                            {/* About Content Wrap End */}
                        </div>
                    </div>
                </div>
                {/* About Wrapper End */}
            </div>
        </div>
        {/* About End */}

        {/* Service Start */}
        <div className="section techwix-service-section-06 section-padding" style={{backgroundImage: "url(images/bg/service-bg6.jpg)"}}>
            <div className="container">
                {/* Service Wrap Start */}
                <div className="service-wrap">
                    <div className="section-title text-center">
                        <h3 className="sub-title color-3">Whats included in service</h3>
                        <h2 className="title">Comprehensive API Integration Services</h2>
                    </div>
                    <div className="service-content-wrap">
                        <div className="row">
                            <div className="col-xl-3 col-sm-6">
                                {/* Service Item Start */}
                                <div className="service-item-05">
                                    <div className="service-img">
                                        <img src="images/ser-icon17.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="/service">Third-Party Integrations</a></h3>
                                        <p>Leverage our proficiency in seamlessly integrating third-party APIs, expanding your software's functionality by connecting with external services and platforms.</p>
                                    </div>
                                </div>
                                {/* Service Item End */}
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                {/* Service Item Start */}
                                <div className="service-item-05">
                                    <div className="service-img">
                                        <img src="images/ser-icon18.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="/service">Data Synchronization Solutions</a></h3>
                                        <p>Ensure consistency and accuracy across your applications with our data synchronization services, synchronizing data seamlessly between different systems for a unified user experience.</p>
                                    </div>
                                </div>
                                {/* Service Item End */}
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                {/* Service Item Start */}
                                <div className="service-item-05">
                                    <div className="service-img">
                                        <img src="images/ser-icon19.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="/service">Scalable API Architecture</a></h3>
                                        <p>Benefit from our expertise in designing scalable API architectures, allowing your software to grow and adapt to evolving needs while maintaining optimal performance and responsiveness.</p>
                                    </div>
                                </div>
                                {/* Service Item End */}
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                {/* Service Item Start */}
                                <div className="service-item-05">
                                    <div className="service-img">
                                        <img src="images/ser-icon20.png" alt=""/>
                                    </div>
                                    <div className="service-content">
                                        <h3 className="title"><a href="/service">Custom API Development</a></h3>
                                        <p>Tailor your integration solutions to fit specific business requirements with our custom API development services, ensuring a perfect alignment between your software and the unique demands of your industry or niche.</p>
                                    </div>
                                </div>
                                {/* Service Item End */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Service Wrap End */}
            </div>
        </div>
        {/* Service End */}

        {/* Service Start */}
        <div className="section techwix-service-section-04 techwix-service-section-07 section-padding-02">
            <div className="container">
                {/* Service Wrap Start */}
                <div className="service-wrap">
                    <div className="section-title text-center">
                        <h3 className="sub-title color-3">Optimal API Solutions</h3>
                        <h2 className="title"> Seamless Connectivity and Enhanced Functionality.</h2>
                    </div>
                    <div className="service-content-wrap">
                        <div className="row">
                            <div className="col-lg-6">
                                {/* Experience Wrap Start */}
                                <div className="experience-wrap">
                                    <img className="shape-1" src="images/shape/experince-shape2.png" alt=""/>
                                    <div className="experience" style={{backgroundImage: "url(images/shape/exp-bg2.jpg)"}}></div>
                                </div>
                                {/* Experience Wrap End */}
                            </div>
                            <div className="col-lg-6">
                                {/* Service Content Start */}
                                <div className="service-content">
                                    <p className="text">Integrating third-party services, streamlining data synchronization, and implementing scalable API architectures. Providing your business a cohesive and dynamic software experience. Custom API development further tailors these integrations to specific needs, empowering companies to adapt, scale, and thrive in the ever-evolving digital ecosystem.</p>
                                    <div className="service-list">
                                        <ul>
                                            <li>
                                                <span className="service-icon"><i className="fas fa-check"></i></span>
                                                <span className="service-text">We always focus on technical excellence</span>
                                            </li>
                                            <li>
                                                <span className="service-icon"><i className="fas fa-check"></i></span>
                                                <span className="service-text"> Wherever you’re going, we bring ideas and excitement</span>
                                            </li>
                                            <li>
                                                <span className="service-icon"><i className="fas fa-check"></i></span>
                                                <span className="service-text">We’re consultants, guides, and partners for brands</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Service Content En */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Service Wrap End */}
            </div>
        </div>
        {/* Service End */}

    </div>
    )
}

export default ApiSolution