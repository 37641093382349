import { useEffect, useRef, useState } from "react";

const OffCanvas = () => {
    const offCanvasRef = useRef(null);
    const [isActive, setIsActive] = useState(false);

    const handleMenuClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const parent = e.currentTarget.parentElement;
        if (parent.classList.contains('active')) {
        parent.classList.remove('active');
        parent.querySelectorAll('.sub-menu').forEach(function (subMenu) {
            subMenu.parentElement.classList.remove('active');
            slideUp(subMenu);
        });
        } else {
        parent.classList.add('active');
        slideDown(e.currentTarget.nextElementSibling);
        getSiblings(parent).forEach(function (item) {
            item.classList.remove('active');
            item.querySelectorAll('.sub-menu').forEach(function (subMenu) {
            subMenu.parentElement.classList.remove('active');
            slideUp(subMenu);
            });
        });
        }
    };

    const slideUp = (target, time) => {
        const duration = time ? time : 500;
        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = duration + 'ms';
        target.style.boxSizing = 'border-box';
        target.style.height = target.offsetHeight + 'px';
        target.style.overflow = 'hidden';
        target.style.height = 0;
        window.setTimeout(() => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
        }, duration);
    };

    const slideDown = (target, time) => {
        const duration = time ? time : 500;
        target.style.removeProperty('display');
        let display = window.getComputedStyle(target).display;
        if (display === 'none') display = 'block';
        target.style.display = display;
        const height = target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.boxSizing = 'border-box';
        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = duration + 'ms';
        target.style.height = height + 'px';
        window.setTimeout(() => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
        }, duration);
    };
    const getSiblings = function (elem) {
        const siblings = [];
        let sibling = elem.parentNode.firstChild;
        while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
        }
        return siblings;
    };

    useEffect(() => {

        const offCanvasMenu = function (ref) {
        const $offCanvasNav = ref.current,
            $subMenu = $offCanvasNav.querySelectorAll('.sub-menu');

        $subMenu.forEach(function (subMenu) {
            if (subMenu.classList.contains('mega-menu')) {
            subMenu.classList.remove('mega-menu');
            subMenu.querySelectorAll('ul').forEach(function (ul) {
                ul.classList.add('sub-menu');
                const menuExpand = document.createElement('span');
                menuExpand.classList.add('menu-expand');
                menuExpand.innerHTML = '+';
                ul.parentElement.insertBefore(menuExpand, ul);
            });
            }
        });
        };

        offCanvasMenu(offCanvasRef);
    }, [offCanvasRef]);

  return (
    <div
      className={`offcanvas offcanvas-start${isActive ? ' active' : ''}`}
      id="offcanvasExample"
      ref={offCanvasRef}
    >
      <div className="offcanvas-header">
        <button
          type="button"
          className="close-btn"
          data-bs-dismiss="offcanvas"
          onClick={() => setIsActive(false)}
        >
          <i className="flaticon-close"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="offcanvas-menu">
          <ul className="main-menu">
            <li className="active-menu">
                <a href="/">Home</a>
                <span className="menu-expand" onClick={handleMenuClick}></span>
                <ul className="sub-menu">
                    <li className="active"><a href="/">Home Main</a></li>
                    <li><a href="/websolutions">Web & App Solutions</a></li>
                    <li><a href="/aisolutions">AI Solutions</a></li>
                    {/* <li><a href="index-3.html">Cyber Security</a></li> */}
                    <li><a href="/apisolutions">API Solutions</a></li>
                </ul>
            </li>
            <li>
                <a href="/about">About Us</a>
            </li>
            <li>
                <a href="#">Pages</a>
                <span className="menu-expand" onClick={handleMenuClick}></span>
                <ul className="sub-menu">
                    {/* <li><a href="team.html">Our Team</a></li> */}
                    <li><a href="/service">Service</a></li>
                    {/* <li><a href="#">Why Choose Us</a></li> */}
                    {/* <li><a href="testimonial.html">Testimonial</a></li> */}
                    <li><a href="/pricing">Pricing</a></li>
                    <li><a href="https://g2dash.com">Login & Register</a></li>
                </ul>
            </li>
            <li>
                <a href="/blog">Blog</a>
            </li>
            <li><a href="/contact">Contact</a></li>
            {/* ... (other menu items) */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OffCanvas;
